<template>
    <div>
        <editor :init="tinyConfig" v-model="local" />
    </div>
</template>

<script>
import { API_BASE_URL, APP_NAME } from "@Platon/const"

import "tinymce/tinymce"
import "tinymce/models/dom/model"
import "tinymce/themes/silver"
import "tinymce/icons/default"
import "tinymce/skins/ui/oxide/skin.css"

//plugins
import "./tinymce/editimage.plugin"
import "tinymce/plugins/image"
import "tinymce/plugins/media"
import "tinymce/plugins/table"
import "tinymce/plugins/advlist"
import "tinymce/plugins/link"
import "tinymce/plugins/autolink"
import "tinymce/plugins/lists"
import "tinymce/plugins/charmap"
import "tinymce/plugins/searchreplace"
import "tinymce/plugins/visualblocks"
import "tinymce/plugins/code"
import "tinymce/plugins/fullscreen"
import "tinymce/plugins/preview"
import "tinymce/plugins/anchor"
import "tinymce/plugins/insertdatetime"
import "tinymce/plugins/help"
import "tinymce/plugins/template"
import "tinymce/plugins/pagebreak"

import Editor from "@tinymce/tinymce-vue"

export default {
    components: { editor: Editor },
    props: {
        config: {
            default: () => {},
            typeof: Object
        },
        value: {
            default: "",
            typeof: String
        }
    },
    data() {
        return {
            init: {
                height: 500,
                model: "dom",
                theme: "silver",
                branding: false,
                image_caption: true,
                selector: "textarea",
                toolbar_sticky: false,
                imagetools_cors_hosts: [APP_NAME],
                placeholder: "Placerat",
                images_upload_handler: this.imageUploadHandler,
                plugins: [
                    "advlist",
                    "autolink",
                    "link",
                    "image",
                    "lists",
                    "charmap",
                    "preview",
                    "anchor",
                    "pagebreak",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "template",
                    "help",
                    "editimage"
                ],
                toolbar:
                    "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | " +
                    "bullist numlist outdent indent | link image | forecolor backcolor | help",
                menubar: "favs file edit view insert format table",
                content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:16px; }"
            }
        }
    },

    methods: {
        imageUploadHandler(blobInfo, progress) {
            const formData = new FormData()
            formData.append("file", blobInfo.blob(), blobInfo.filename())

            return new Promise((resolve, reject) => {
                this.$api({
                    baseURL: API_BASE_URL,
                    method: "post",
                    url: `files/upload/category/${this.config.fileCategorySlug}`,
                    data: formData,
                    onUploadProgress: (progressEvent) => {
                        progress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
                    }
                })
                    .then((res) => {
                        const imageId = res.data.id
                        resolve(platonFileUrl(imageId))
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
    },

    computed: {
        tinyConfig() {
            return Object.assign(this.init, this.config)
        },

        local: {
            get() {
                return this.value
            },

            set(val) {
                return this.$emit("input", val)
            }
        }
    }
}
</script>
